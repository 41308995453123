import { AngabenDetails } from './AngabenDetails';
import styles from './Angaben.module.scss';

export const Angaben = () => {
  return (
    <div className={styles.angaben}>
      <AngabenDetails />
    </div>
  );
};
