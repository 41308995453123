import { Asterisk } from 'components/Asterisk';
import { Page } from 'components/Page/Page';
import {
  SuccessMessage,
  SuccessMessageHeader,
} from 'components/SuccessMessage';
import { useAppSelector } from 'store/hooks';
import {
  AppName,
  AppNamesGerman,
  PageNames,
  StoreProps,
} from 'types/app.types';
import { aemTracking as aemTrackingHelper } from 'helpers/aem-tracking';
import { createTrackingObject } from 'helpers/create-tracking-object';
import {
  getYearlyNormalPrice,
  getYearlyReducedPrice,
} from 'helpers/get-tracking-calculated-values';
import { getInsuranceStartDate } from 'helpers/date-format-converter';
import { useEffect } from 'react';

export const SuccessView = () => {
  const { showAsterisk } = useAppSelector(
    (state: StoreProps) => state.userInterface,
  );
  const { person, aemTracking } = useAppSelector((state) => state);
  const { appProps } = aemTracking;

  const calculatedValue = person.pricing.paymentMethodPrice !== 0;

  const trackingObject = createTrackingObject({
    event: 'PageLoaded',
    insuranceStart: getInsuranceStartDate(person.startInsuranceDate),
    productCombination: person.productCombination,
    calculatedValue: getYearlyNormalPrice(person.pricing.paymentMethodPrice),
    calculatedValueReduced: getYearlyReducedPrice(
      person.pricing.discountAmount,
      person.pricing.paymentMethodPrice,
    ),
    conversionType: AppName[appProps.appName as AppNamesGerman],
    actionNumber: person.actionNumber,
    transactionID: person.businessId,
    appProps: {
      ...appProps,
      pageName: `${appProps.appNameShort}:${PageNames.SuccessPage}`,
    },
    transactionID_VSNR: person.transactionOfferNumber,
  });

  useEffect(() => {
    if (calculatedValue) aemTrackingHelper.push(trackingObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedValue]);

  return (
    <Page
      header={<SuccessMessageHeader />}
      view={
        <>
          <SuccessMessage />
          {showAsterisk && <Asterisk />}
        </>
      }
    />
  );
};
