import { AemWindow } from 'types/app.types';

const trackingWindow = window as unknown as AemWindow;

const init = () => {
  trackingWindow.appEventData = trackingWindow.appEventData || [];
};

const push = (data: unknown) => {
  trackingWindow.appEventData.push(data);
};

export const aemTracking = {
  init,
  push,
  trackingWindow,
};
