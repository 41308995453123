import { FC } from 'react';
import { PageProps, PageWrapperProps } from './Page.types';

const PageWrapper: FC<PageWrapperProps> = ({ children }) => {
  return (
    <div className="esc_container esc_container--l esc_box">{children}</div>
  );
};

export const Page: FC<PageProps> = ({ header, view }) => {
  return (
    <>
      {header && header}
      <PageWrapper>{view}</PageWrapper>
    </>
  );
};
