import { heading5 } from 'helpers/handling-header-css-class';
import { handleGenderType } from 'helpers/gender-type-reconizer';
import { Person } from 'types';
import { useAppSelector } from 'store/hooks';
import { PersonProps } from 'types/app.types';
import styles from './Angaben.module.scss';

export const CreateVPNode = ({
  data,
  width,
}: {
  data: PersonProps;
  width: number;
}) => {
  const { isPolicyHolderAlsoInsuredPerson, policyHolder, insuredPersons } =
    data;
  const insuredPersonsDetails: Person[] = [policyHolder, ...insuredPersons];
  const { dimensions } = useAppSelector((state) => state.userInterface);

  const getHeader = (index: number) => {
    let header = null;
    if (index === 0) {
      header = isPolicyHolderAlsoInsuredPerson ? (
        <p className={heading5(dimensions.width)}>
          Versicherungsnehmer und 1. zu versichernde Person
        </p>
      ) : (
        <p className={heading5(dimensions.width)}>Versicherungsnehmer</p>
      );
    } else {
      header = isPolicyHolderAlsoInsuredPerson ? (
        <p className={heading5(dimensions.width)}>
          {index + 1}. zu versichernde Person
        </p>
      ) : (
        <p className={heading5(dimensions.width)}>
          {index}. zu versichernde Person
        </p>
      );
    }

    return header;
  };
  return (
    <ul className={styles.angaben__list}>
      {insuredPersonsDetails.map((person: Person, index: number) => (
        <div key={`${person.lastName}`}>
          {getHeader(index)}

          <div className={styles.tarifSection}>
            <p className={heading5(dimensions.width)}>Anrede</p>
            <p className={styles.tarifSection__values}>
              {handleGenderType({ salutation: person.salutation })}
            </p>
          </div>

          <div className={styles.tarifSection}>
            <p className={heading5(dimensions.width)}>Vorname, Name</p>
            <p className={styles.tarifSection__values}>
              {`${person.firstName} ${person.lastName}`}
            </p>
          </div>
        </div>
      ))}
    </ul>
  );
};
