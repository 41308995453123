import Button, { ButtonProps } from '@eg/elements/Button';

import './IddButton.scss';

export type IddButtonProps = ButtonProps & {
  leftSection: React.FC | string;
  rightSection: React.FC | string;
};

export const IddButton: React.FC<IddButtonProps> = (props: IddButtonProps) => {
  const divClassName = 'IddButton';
  const { leftSection, rightSection } = props;
  return (
    <Button {...props} style={{ padding: '0 24px', fontWeight: 400 }}>
      <div className={`${divClassName}__div`}>
        <div
          className={`${divClassName}__LeftSection`}
          style={{ minWidth: '14px' }}
        >
          {typeof leftSection === 'string' ? (
            leftSection
          ) : (
            <props.leftSection />
          )}
        </div>
        <span>&nbsp;|&nbsp;</span>
        <div
          className={`${divClassName}__RightSection`}
          style={{ padding: '0 4px', textAlign: 'left' }}
        >
          {typeof rightSection === 'string' ? (
            rightSection
          ) : (
            <props.rightSection />
          )}
        </div>
      </div>
    </Button>
  );
};
