import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInterfaceInitialState } from 'store/constants/initial-state';
import { ErrorState } from 'types/app.types';
import { Dimensions } from '../../types/app.types';

const initialState = UserInterfaceInitialState;
const userInterfaceSlice = createSlice({
  name: 'userInterface',
  initialState,
  reducers: {
    setIsAppInitialized: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAppInitialized: action.payload };
    },
    setIsUserAuthorized: (state, action: PayloadAction<boolean>) => {
      return { ...state, isUserAuthorized: action.payload };
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      return { ...state, isError: action.payload };
    },
    setShowAsterisk: (state, action: PayloadAction<boolean>) => {
      return { ...state, showAsterisk: action.payload };
    },
    setLoaderState: (
      state,
      action: PayloadAction<{
        show: boolean;
        infoLabel: string;
      }>,
    ) => {
      return { ...state, loaderState: action.payload };
    },
    setModalState: (
      state,
      action: PayloadAction<{
        isModalOpen: boolean;
        modalText: string;
      }>,
    ) => {
      return { ...state, modalState: action.payload };
    },
    setErrorState: (state, action: PayloadAction<ErrorState>) => {
      return {
        ...state,
        requestStatus: action.payload.status,
        errorState: {
          state: action.payload.state,
          status: action.payload.status,
          title: action.payload.title,
          content: action.payload.content,
        },
      };
    },
    setRequestStatus: (state, action: PayloadAction<number>) => {
      return { ...state, requestStatus: action.payload };
    },
    setSuccessMessage: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        success: action.payload,
      };
    },
    setPaymentMethodConfirmation: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isPaymentMethodConfirmed: action.payload,
      };
    },
    setPaymentMethodConfirmationError: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isPaymentMethodConfirmationError: action.payload,
      };
    },
    setIsFormSubmitted: (state, action: PayloadAction<boolean>) => {
      return { ...state, isFormSubmitted: action.payload };
    },
    setAemHeaderMarginTop: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      return { ...state, aemHeaderMarginTop: action.payload };
    },
    setDimensions: (state, action: PayloadAction<Dimensions>) => {
      return { ...state, dimensions: action.payload };
    },
    setProductName: (state, actions: PayloadAction<string>) => {
      return { ...state, productName: actions.payload };
    },
  },
});

export const {
  setIsAppInitialized,
  setIsUserAuthorized,
  setIsError,
  setShowAsterisk,
  setLoaderState,
  setModalState,
  setErrorState,
  setSuccessMessage,
  setPaymentMethodConfirmation,
  setPaymentMethodConfirmationError,
  setRequestStatus,
  setIsFormSubmitted,
  setAemHeaderMarginTop,
  setDimensions,
} = userInterfaceSlice.actions;

export const userInterfaceReducer = userInterfaceSlice;
export default userInterfaceReducer;
