import { SYS_ENV } from 'config';
import buildInfo from '../build-info.json';
import { isMobile } from './is-mobile';
import {
  AppNamesEnglish,
  AppNamesShort,
  AppProps,
  EventDetails,
  TrackingEvent,
} from '../types/app.types';

interface TrackingObjectParams {
  event: TrackingEvent;
  eventDetails?: EventDetails;
  insuranceStart: string;
  productCombination: string;
  calculatedValue: number;
  calculatedValueReduced: number;
  conversionType?: AppNamesEnglish;
  actionNumber: string;
  transactionID: string;
  transactionID_VSNR: string;
  appProps?: AppProps;
}

export const createTrackingObject = (trackingParams: TrackingObjectParams) => {
  const transactionAttributes = {
    calculatedValue: trackingParams.calculatedValue,
    calculatedValueReduced: trackingParams.calculatedValueReduced,
    offer: {
      actionNumber: trackingParams.actionNumber,
      transactionID: trackingParams.transactionID,
    },
  };

  const transactionAttributesWithConversionType = trackingParams.conversionType
    ? {
        ...transactionAttributes,
        conversionType: trackingParams.conversionType,
      }
    : transactionAttributes;

  const pageInfo = {
    sysEnv: SYS_ENV,
    issueDate: `${trackingParams.appProps?.appName}|${buildInfo.buildDate}`,
  };

  const pageInfoWithName = trackingParams.appProps?.pageName
    ? {
        ...pageInfo,
        pageName: trackingParams.appProps?.pageName,
      }
    : pageInfo;

  const trackingObject = {
    event: trackingParams.event,
    page: {
      attributes: {
        displayedBrand: 'ERGO',
        viewPort: isMobile() ? 'mobile' : 'desktop',
      },
      pageInfo: pageInfoWithName,
      category: {
        pagePurpose: 'Sale',
        pageType: trackingParams.appProps?.appName,
        primaryCategory: trackingParams.appProps?.appName,
      },
    },
    product: [
      {
        attributes: {
          insuranceStart: trackingParams.insuranceStart,
          productBranch: 'Kranken',
          productCombination: trackingParams.productCombination,
          productOwner: 'ERGO',
        },
        category: {
          primaryCategory: 'ZahnZusatz',
        },
        productInfo: {
          productName:
            trackingParams.appProps?.appNameShort ||
            AppNamesShort.angebotswandlung,
        },
      },
    ],
    transaction: {
      transactionID: trackingParams.transactionID_VSNR,
      attributes: transactionAttributesWithConversionType,
    },
  };

  const trackingObjectWithEventDetails = trackingParams.eventDetails
    ? {
        ...trackingObject,
        eventdetails: {
          clickedElement: trackingParams.eventDetails?.clickedElement,
          clickType: trackingParams.eventDetails?.clickType,
        },
      }
    : trackingObject;

  const trackingObjectWithOrWithoutError = trackingParams.appProps?.errorType
    ? {
        ...trackingObjectWithEventDetails,
        miscellaneous: {
          errors: {
            errorFlag: true,
            errorType: trackingParams.appProps?.errorType,
          },
        },
      }
    : trackingObjectWithEventDetails;

  return trackingObjectWithOrWithoutError;
};
