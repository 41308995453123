export const getYearlyNormalPrice = (normalPrice: number) => {
  const calculatedValue = 12 * normalPrice;

  return Number(calculatedValue.toFixed(2));
};

export const getYearlyReducedPrice = (
  discountAmount: number,
  normalPrice: number,
) => {
  const calculatedDiscountValue =
    discountAmount > 0
      ? 6 * discountAmount + 6 * normalPrice
      : 12 * normalPrice;

  return Number(calculatedDiscountValue.toFixed(2));
};
