import { ErrorMessage } from 'components/ErrorMessage';
import { Page } from 'components/Page/Page';
import { useEffect } from 'react';
import { createTrackingObject } from 'helpers/create-tracking-object';
import { aemTracking as aemTrackingHelper } from 'helpers/aem-tracking';
import { getInsuranceStartDate } from 'helpers/date-format-converter';
import {
  getYearlyNormalPrice,
  getYearlyReducedPrice,
} from 'helpers/get-tracking-calculated-values';
import { useAppSelector } from 'store/hooks';

export const ErrorFallbackView = () => {
  const { person, aemTracking } = useAppSelector((state) => state);
  const { appProps } = aemTracking;

  const trackingObject = createTrackingObject({
    event: 'PageLoaded',
    insuranceStart: getInsuranceStartDate(person.startInsuranceDate),
    productCombination: person.productCombination,
    calculatedValue: getYearlyNormalPrice(person.pricing.paymentMethodPrice),
    calculatedValueReduced: getYearlyReducedPrice(
      person.pricing.discountAmount,
      person.pricing.paymentMethodPrice,
    ),
    actionNumber: person.actionNumber,
    transactionID: appProps.transactionID || '',
    appProps,
    transactionID_VSNR: person.transactionOfferNumber,
  });

  useEffect(() => {
    aemTrackingHelper.push(trackingObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Page view={<ErrorMessage />} />;
};
