import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchInitialOffer } from 'services/api/api-calls/get-initial-offer-data-call';

import { PersonInitialState } from 'store/constants/initial-state';
import { PersonProps } from 'types/app.types';

const initialState = PersonInitialState;
const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    setIsAdviceWaiver: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAdviceWaiver: action.payload };
    },
    setBankData: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        policyPayer: { ...state.policyPayer, iban: action.payload },
      };
    },
  },
  extraReducers: {
    [fetchInitialOffer.fulfilled.type]: (
      _state,
      action: PayloadAction<PersonProps>,
    ) => {
      return action.payload;
    },
  },
});

export const { setIsAdviceWaiver, setBankData } = personSlice.actions;

export const personReducer = personSlice;
