import { Endpoints } from 'types';
import { Environment, SysEnv } from 'types/app.types';
import { NODE_ENV } from './config';

export const CONFIG_BACKEND_ENDPOINTS: Record<Endpoints, string> = {
  [Endpoints.fileDownload]: 'form/download-file',
  [Endpoints.finalOffer]: 'form',
  [Endpoints.initialOfferData]: `form/initial-offer-data?angebotsnr=`,
  [Endpoints.ibanValidation]: 'form/check-bank-data',
};

const EnvironmentToSysEnv = {
  [Environment.development]: SysEnv.development,
  [Environment.dvit]: SysEnv.dvit,
  [Environment.fait]: SysEnv.fait,
  [Environment.abn]: SysEnv.abn,
  [Environment.production]: SysEnv.production,
};

export const SYS_ENV: SysEnv =
  EnvironmentToSysEnv[NODE_ENV as Environment] || SysEnv.production;

export const fileName = 'Versicherungsunterlagen.pdf';

export const appId = 'ZAHN_PPZ_&_AUGEN_Angebotswandlung';

export enum UiPaths {
  empty = '',
  root = '/',
  success = '/success',
  error = '/error',
  anyOther = '/*',
}

export const REQUEST_FORM_LABELS = { download: 'download' };
