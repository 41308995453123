import { Angaben } from 'components/Angaben';
import { Idd } from 'components/Idd';
import { Asterisk } from 'components/Asterisk';
import { WelcomeContainer } from 'components/WelcomeContainer/WelcomeContainer';
import { Gdpr } from 'components/Gdpr';
import { PaymentComponent } from 'components/PaymentComponent/PaymentComponent';
import { Page } from 'components/Page/Page';
import { Header } from 'components/Header';
import { useAppSelector } from 'store/hooks';
import { createTrackingObject } from 'helpers/create-tracking-object';
import {
  getYearlyNormalPrice,
  getYearlyReducedPrice,
} from 'helpers/get-tracking-calculated-values';

import { getInsuranceStartDate } from 'helpers/date-format-converter';
import { aemTracking as aemTrackingHelper } from 'helpers/aem-tracking';
import { useEffect } from 'react';

export const OfferView = () => {
  const { isOriginalOffer } = useAppSelector((state) => state.person);
  const { showAsterisk } = useAppSelector((state) => state.userInterface);
  const { person, aemTracking, userInterface } = useAppSelector(
    (state) => state,
  );

  const { appProps } = aemTracking;

  const { isFormSubmitted } = userInterface;

  const calculatedValue = person.pricing.paymentMethodPrice !== 0;

  const trackingObject = createTrackingObject({
    event: 'PageLoaded',
    insuranceStart: getInsuranceStartDate(person.startInsuranceDate),
    productCombination: person.productCombination,
    calculatedValue: getYearlyNormalPrice(person.pricing.paymentMethodPrice),
    calculatedValueReduced: getYearlyReducedPrice(
      person.pricing.discountAmount,
      person.pricing.paymentMethodPrice,
    ),
    actionNumber: person.actionNumber,
    transactionID: person.businessId,
    appProps,
    transactionID_VSNR: person.transactionOfferNumber,
  });

  useEffect(() => {
    if (calculatedValue && !isFormSubmitted)
      aemTrackingHelper.push(trackingObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedValue]);

  return (
    <Page
      header={<Header />}
      view={
        <>
          <WelcomeContainer />
          <Angaben />
          <Gdpr />
          <PaymentComponent isOriginalOffer={isOriginalOffer} />
          <Idd />
          {showAsterisk && <Asterisk />}
        </>
      }
    />
  );
};
