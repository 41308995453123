import { applyGermanUmlauts } from './apply-german-umlauts';

export const translatePeriods = (period: string) => {
  switch (period) {
    case 'monthly':
      return 'monatlich';
      break;
    case 'quarterly':
      return applyGermanUmlauts('vierteljaehrlich');
      break;
    case 'halfYearly':
      return applyGermanUmlauts('halbjaehrlich');
      break;
    case 'yearly':
      return applyGermanUmlauts('jaehrlich');
      break;
    default:
      return '';
  }
};
