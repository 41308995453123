import { Person, PersonTitle } from 'types';

export interface InitialLoaderState {
  show: boolean;
  infoLabel: string;
}

export type Dimensions = {
  height: number;
  width: number;
};

export interface UserInterfaceProps {
  isAppInitialized: boolean;
  isUserAuthorized: boolean;
  loaderState: InitialLoaderState;
  isButtonLocked: boolean;
  isMobileView: boolean;
  isError: boolean;
  showAsterisk: boolean;
  modalState: {
    isModalOpen: boolean;
    modalText: string;
  };
  isModalOpen: boolean;
  modalText: string;
  errorState: {
    state: boolean;
    status: number;
    title: string;
    content: string;
  };
  success: boolean;
  isPaymentMethodConfirmed: boolean;
  isPaymentMethodConfirmationError: boolean;
  requestStatus: number;
  isFormSubmitted: boolean;
  aemHeaderMarginTop: number | undefined;
  dimensions: Dimensions;
}

export type PolicyPayer = {
  salutation: PersonTitle;
  firstName: string;
  lastName: string;
  iban: string;
  bankName: string;
  bankCity: string;
  bic: string;
};

export interface PersonProps {
  businessId: string;
  isOriginalOffer: boolean | null;
  currentOffers: string[];
  previousOffers: string[];
  startInsuranceDate: string;
  insuranceName: string;
  productName: string;
  productCombination: string;
  actionNumber: string;
  productDetails: string[];
  isPolicyHolderAlsoInsuredPerson: boolean;
  salesStrategy: string;
  policyHolder: Person;
  policyPayer: PolicyPayer;
  insuredPersons: Person[];
  pricing: {
    paymentMethodPrice: number;
    paymentMethod: string;
    discountMonthsCount: number;
    discountAmount: number;
    oldOfferAmount: number;
  };
  isAdviceWaiver: boolean;
  transactionOfferNumber: string;
}

export interface PricingProps {
  paymentMethodPrice: number;
  paymentMethod: string;
  discountMonthsCount?: number;
  discountAmount?: number;
}

export interface StoreProps {
  userInterface: UserInterfaceProps;
  person: PersonProps;
  aemTracking: AemTrackingProps;
}

export interface AemWindow extends Window {
  [key: string]: unknown;
  envUrl: string;
  appEventData: unknown[];
  NODE_ENV: string;
  CONFIG_BACKEND_API_URL: string;
}

export type Product = {
  attributes: {
    insuranceStart: string;
    productBranch: string;
    productCombination: string;
    productOwner: string;
  };
  category: {
    primaryCategory: string;
  };
  productInfo: {
    productName: string;
  };
};

export type Page = {
  attributes: {
    displayedBrand: string;
    viewPort: string;
  };
  pageInfo: {
    sysEnv: string;
    issueDate: string;
    pageName: string;
  };
  category: {
    pagePurpose: string;
    pageType: string;
    primaryCategory: string;
  };
};

export type TrackingEvent = '' | 'PageLoaded' | 'GeneralClick';

export interface EventDetails {
  clickedElement: string;
  clickType: string;
}

export interface AemTrackingProps {
  event: TrackingEvent;
  eventdetails?: EventDetails;
  page: Page;
  product: Product[];
  transaction: {
    attributes: {
      conversionType: AppNamesEnglish | '';
      calculatedValue: number;
      calculatedValueReduced: number;
      offer: {
        actionNumber: string;
        transactionID: string;
      };
    };
  };
  miscellaneous: { errors: { errorFlag: boolean; errorType: string } };
  appProps: AppProps;
}

export enum PaymentMethodChoices {
  monthly = 'monthly',
  quarterly = 'quarterly',
  halfYearly = 'halfYearly',
  yearly = 'yearly',
}

export enum PaymentMethodInGerman {
  quarterly = 'vierteljährlich',
  halfYearly = 'halbjährlich',
  yearly = 'jährlich',
  monthly = 'monatlich',
}

export enum AccordionTitles {
  goodToKnow = 'Gut zu wissen',
  tariffAndContribution = 'Tarif und Beitrag',
  startInsuranceDate = 'Versicherungsbeginn',
  personalData = 'Persönliche Daten',
}
export interface Accordion {
  title: AccordionTitles;
  content: JSX.Element;
}

export type ErrorState = {
  state: boolean;
  status: number;
  title: string;
  content: string;
};

type OfferType =
  | AppNamesShort.angebotswandlung
  | AppNamesShort.tarifwechsel
  | AppNamesShort.upselling;

export type AppProps = {
  appName: AppNamesGerman | '';
  appNameShort: OfferType | '';
  pageName?: string | undefined;
  errorMsg: string | undefined;
  errorType: string | undefined;
  transactionID: string | '';
};

export enum AppNamesGerman {
  OfferSale = 'Angebotswandlung',
  ChangeTariff = 'Tarifwechsel',
  Upselling = 'Upselling',
}
export enum AppNamesEnglish {
  OfferSale = 'OfferSale',
  ChangeTariff = 'ChangeTariff',
  Upselling = 'Upselling',
}

export const AppName = {
  [AppNamesGerman.OfferSale]: AppNamesEnglish.OfferSale,
  [AppNamesGerman.ChangeTariff]: AppNamesEnglish.ChangeTariff,
  [AppNamesGerman.Upselling]: AppNamesEnglish.Upselling,
};

export enum AppNamesShort {
  angebotswandlung = 'anw',
  tarifwechsel = 'tw',
  upselling = 'ups',
}

export enum HttpStatus {
  success = 200,
  badRequest = 400,
  unauthorized = 401,
  notFound = 404,
  offerExpired = 406,
  serverError = 500,
  undefined = 0,
}

export const RequestStatusCode = {
  [HttpStatus.unauthorized]: 'unauthorized',
  [HttpStatus.badRequest]: 'badRequest',
  [HttpStatus.notFound]: 'notFound',
  [HttpStatus.offerExpired]: 'offerExpired',
  [HttpStatus.serverError]: 'serverError',
  [HttpStatus.undefined]: 'undefined',
};

export enum RequestStatusName {
  badRequest = 'badRequest',
  notFound = 'notFound',
  offerExpired = 'offerExpired',
  serverError = 'serverError',
  undefined = 'undefined',
}
export const ErrorMessages = {
  [RequestStatusCode[HttpStatus.badRequest]]: 'Ungültige Anforderung',
  [RequestStatusCode[HttpStatus.notFound]]: 'Angebot nicht gefunden',
  [RequestStatusCode[HttpStatus.offerExpired]]: 'Angebot abgelaufen',
  [RequestStatusCode[HttpStatus.serverError]]: 'Serverfehler',
  [RequestStatusCode[HttpStatus.undefined]]: 'Unbehandelter Fehler',
};

export const MiscellaneousErrorTypes = {
  [RequestStatusCode[HttpStatus.badRequest]]: 'Link doppelt verwendet',
  [RequestStatusCode[HttpStatus.notFound]]:
    'Angebot nicht gefunden + not found',
  [RequestStatusCode[HttpStatus.offerExpired]]: 'Link abgelaufen',
  [RequestStatusCode[HttpStatus.serverError]]: 'System antwortet nicht',
};

export enum PageNames {
  anw_MainPage = 'anw:Angebot Wiederaufruf',
  SuccessPage = 'Abschluss Feedback',
  ServerErrorPage = ':Fehler:System antwortet nicht',
  OfferExpiredPage = ':Fehler:Link abgelaufen',
  BadRequestPage = ':Fehler:Link doppelt verwendet',
  Unauthorized = ':Fehler:Unauthorized',
}

export const ErrorPageName = {
  [RequestStatusCode[HttpStatus.unauthorized]]: PageNames.Unauthorized,
  [RequestStatusCode[HttpStatus.badRequest]]: PageNames.BadRequestPage,
  [RequestStatusCode[HttpStatus.notFound]]: PageNames.BadRequestPage,
  [RequestStatusCode[HttpStatus.offerExpired]]: PageNames.OfferExpiredPage,
  [RequestStatusCode[HttpStatus.serverError]]: PageNames.ServerErrorPage,
};

export enum SysEnv {
  development = 'DEV',
  dvit = 'DVIT',
  fait = 'FAIT',
  abn = 'ABN',
  production = 'PROD',
}

export enum Environment {
  development = 'development',
  dvit = 'dvit',
  fait = 'fait',
  abn = 'abn',
  production = 'production',
}
