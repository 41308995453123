import { useAppSelector } from 'store/hooks';
import { getInsuranceStartDate } from 'helpers/date-format-converter';
import { heading5 } from 'helpers/handling-header-css-class';
import styles from './Angaben.module.scss';

export const StartInsuranceDate = () => {
  const {
    person,
    userInterface: { dimensions },
  } = useAppSelector((state) => state);

  return (
    <div className={styles.angaben__content}>
      <p className={heading5(dimensions.width)}>Start der Versicherung</p>
      <p>
        {person.startInsuranceDate &&
          getInsuranceStartDate(person.startInsuranceDate)}
      </p>
    </div>
  );
};
