import { useAppSelector } from 'store/hooks';
import Card from '@eg/elements/Card';
import { welcomeContainer } from 'store/constants/components-static-texts';
import { heading2, heading3 } from 'helpers/handling-header-css-class';
import { handleGenderType } from 'helpers/gender-type-reconizer';
import styles from './WelcomeContainer.module.scss';

export const WelcomeContainer = () => {
  const { policyHolder } = useAppSelector((state) => state.person);
  const { dimensions } = useAppSelector((state) => state.userInterface);
  const { salutation, firstName, lastName } = policyHolder;
  return (
    <div className={styles.welcomeContainer}>
      <div className={styles.header}>
        <p className={heading2(dimensions.width)}>
          {welcomeContainer.greeting} <br />
          {`${handleGenderType({ salutation })} ${firstName} ${lastName}`}
        </p>
      </div>

      <Card>
        <div className={styles.title}>
          <h3 className={heading3(dimensions.width)}>
            {welcomeContainer.message}
          </h3>
        </div>

        <div className={styles.container}>
          <div className={styles.flex_col}>
            {Object.values(welcomeContainer.steps).map((item, index) => (
              <div key={`${item} ${index + 1}`} className={styles.flex_row}>
                <div className={styles.index}>{index + 1}</div>
                <p className={styles.item}>{item}</p>
              </div>
            ))}
          </div>
          <div className={styles.bottom_text}>
            <p>{welcomeContainer.info}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};
