import { configureStore } from '@reduxjs/toolkit';
import { personReducer } from './reducers/person-data-reducer';
import { aemTrackingReducer } from './reducers/aem-tracking-reducer';
import { userInterfaceReducer } from './reducers/user-interface-reducer';

const reducer = {
  [personReducer.name]: personReducer.reducer,
  [aemTrackingReducer.name]: aemTrackingReducer.reducer,
  [userInterfaceReducer.name]: userInterfaceReducer.reducer,
};
export const store = configureStore({ reducer });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
