import Price from '@eg/elements/Price';
import { PaymentMethodChoices, PaymentMethodInGerman } from 'types/app.types';
import { useAppSelector } from '../../store/hooks';
import { getAemHeaderHeight, headerContentWidth } from './Header.helper';
import styles from './Header.module.scss';

export const Header = () => {
  const { person } = useAppSelector((state) => state);

  const paymentMethod = () => {
    switch (person.pricing.paymentMethod) {
      case PaymentMethodChoices.quarterly:
        return PaymentMethodInGerman.quarterly;

      case PaymentMethodChoices.halfYearly:
        return PaymentMethodInGerman.halfYearly;

      case PaymentMethodChoices.yearly:
        return PaymentMethodInGerman.yearly;

      case PaymentMethodChoices.monthly:
      default:
        return PaymentMethodInGerman.monthly;
    }
  };

  const isDiscount = person.pricing.discountAmount > 0 ? '*' : '';

  return (
    <div
      style={{
        top: getAemHeaderHeight(),
      }}
      className={styles.price_header}
    >
      <div
        style={{
          width: headerContentWidth(),
        }}
        className={styles.headerContentWrap}
      >
        <div className={styles.label_field_container}>
          <div className={styles.label}>ERGO {person.insuranceName}</div>
          <div className={styles.description}>{person.productName}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.price_field_container}>
            <div className={styles.price_field}>
              <Price
                value={
                  person.pricing.discountAmount > 0
                    ? person.pricing.discountAmount
                    : person.pricing.paymentMethodPrice
                }
                unstyled
              />
              <div className={styles.payment_method}>
                <span className={styles.price} />
                {paymentMethod()}
                {isDiscount}
                <div />
              </div>
            </div>
            {person.pricing.discountMonthsCount > 0 && (
              <div>
                <span className={styles.price_small_text}>
                  Ab&nbsp;dem&nbsp;{person.pricing.discountMonthsCount + 1}
                  .&nbsp;Monat&nbsp;
                  <Price
                    value={
                      person.pricing.paymentMethodPrice > 0
                        ? person.pricing.paymentMethodPrice
                        : undefined
                    }
                    unstyled
                  />
                  *
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
