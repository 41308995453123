import { useEffect, useLayoutEffect } from 'react';
import { Provider } from '@eg/elements/Provider';
import { LoaderDimmed } from 'components/Loader/LoaderDimmed';
import { useSearchParams } from 'react-router-dom';
import { Modal } from 'components/Modal/Modal';
import { setShowAsterisk } from 'store/reducers/user-interface-reducer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getInititalOfferData } from 'services/api/api-calls/get-initial-offer-data-call';
import { handleScreenResize } from 'components/Header/Header.helper';
import { StoreProps } from 'types/app.types';
import { OfferView } from 'views/OfferView';
import { ErrorFallbackView } from 'views/ErrorFallbackView';
import { SuccessView } from 'views/SuccessView';
import { appId } from 'config';
import { aemTracking as aemTrackingHelper } from 'helpers/aem-tracking';
import { aemTrackingStatus } from 'helpers/aem-tracking-status';
import styles from './App.module.scss';

export const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { person, aemTracking } = useAppSelector((state) => state);
  const { isAppInitialized, success, errorState } = useAppSelector(
    (state: StoreProps) => state.userInterface,
  );
  const { appProps } = aemTracking;
  const [queryParams] = useSearchParams();
  const angebotsnr = queryParams.get('angebotsnr') || '';

  useLayoutEffect(() => {
    aemTrackingHelper.init();
    aemTrackingStatus.set({ appId, isTracking: true });
  }, []);

  useLayoutEffect(() => {
    handleScreenResize({ dispatch });
    getInititalOfferData({
      angebotsnr,
      dispatch,
      appProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [angebotsnr, dispatch]);

  useEffect(() => {
    if (person.pricing.discountAmount) {
      dispatch(setShowAsterisk(true));
    }
  }, [dispatch, person.pricing.discountAmount]);

  const showApplicationViews = success ? <SuccessView /> : <OfferView />;

  const showError = errorState.state && <ErrorFallbackView />;

  return (
    <Provider className={styles.root} theme="ergo-one">
      <LoaderDimmed />
      {isAppInitialized && showApplicationViews}
      {showError}
      <Modal />
    </Provider>
  );
};
