import { Salutation } from 'types';

export const welcomeContainer = {
  gender: {
    male: { type: 'male', dear: 'geehrter', salutation: 'Herr' as Salutation },
    female: {
      type: 'female',
      dear: 'geehrte',
      salutation: 'Frau' as Salutation,
    },
  },
  greeting: 'Ihr persönliches Angebot,',
  message: "So einfach geht's.",
  info: `Danach erhalten Sie Ihre Vertragsunterlagen innerhalb weniger Tage.`,
  steps: {
    first: 'Angaben prüfen und bestätigen',
    second: 'Unterlagen herunterladen',
    third: 'Online abschließen',
  },
};

export enum BottomInfo {
  address = `Ich ermächtige die ERGO Krankenversicherung AG (Gläubiger-ID DE25EDK00000041713), Zahlungen von meinem Konto mittels Lastschrift einzuziehen.`,
  zugleich = `Zugleich weise ich mein Kreditinstitut an, die von der ERGO Krankenversicherung AG auf mein Konto gezogenen Lastschriften einzulösen. Der SEPA-Basislastschrift-Einzug wird mir spätestens 5 Kalendertage im Voraus unter Angabe der weiteren Fälligkeitstermine angekündigt.`,
  hinweis = `: Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Dabei gelten die mit meinem Kreditinstitut vereinbarten Bedingungen
        `,
  hinweise = `Hinweise zum SEPA-Lastschriftmandat`,
}

export enum ModalTexts {
  hinweise = 'Sollte bereits ein Mandat für die oben genannte Kontoverbindung bestehen, bin ich damit einverstanden, dass das von mir bereits erteilte SEPA-Lastschriftmandat auch für den Einzug der Beiträge für diesen Versicherungsvertrag genutzt wird.',
  abschluss = 'Ihre IBAN finden Sie auf der Vorderseite Ihrer Bankkarte oder auf Ihrem Kontoauszug.',
}

export enum IbanResponseMessages {
  nonSepaArea = 'Die angegebene IBAN ist nicht gültig. Nur eine europäische IBAN ist erlaubt.',
  MODIFIEDnonSepaArea = 'Bitte geben Sie eine IBAN von einer Bank aus dem SEPA-Zahlungsraum an.',
}

export enum IbanSuccessValidationTexts {
  label = `Ihre Kontoverbindung wurde erfolgreich geprüft. Die zugehörige BIC
            wurde automatisch ergänzt.`,
  bic = ' / Geldinstitut: ',
}
export enum Placeholders {
  iban = 'DE12 3456 7890 1234 5678 90',
}

export enum IbanComponentLabels {
  header = 'Geben Sie Ihre Bankverbindung an',
  subheader = 'Für einen Online-Abschluss benötigt der Versicherer ein SEPA-Lastschriftmandat.',
  salutation = 'Kontoinhaber',
}

export const ErrorMessages = {
  badRequest: {
    title: 'Leider ist ein Fehler aufgetreten.',
    content: `Dies kann folgende Gründe haben:

  - Sie haben dieses Angebot bereits abgeschlossen
  - das Angebot ist veraltet
  - das Produkt ist nicht mehr abschlussfähig
  
Gerne stehen wir Ihnen auch mit unserem gebührenfreien Beratungsservice unter 0800 / 666 9000 oder mit unseren Spezialisten im Live-Chat zur Verfügung.`,
  },

  engine: {
    title: 'Zugriff derzeit nicht möglich.',
    content: `Leider ist momentan aufgrund von technischen Problemen ein Onlineabschluss Ihres Angebots nicht möglich. Bitte kontrollieren Sie den Link in der Adresszelle aut Schreibfehler oder versuchen Sie es zu einem späteren Zeitpunkt noch einmal.
    
Gerne stehen wir Ihnen auch mit unserem gebührenfreien Beratungsservice unter 0800 / 666 9000 oder mit unseren Spezialisten im Live-Chat zur Verfügung.

Sie können natürlich auch Ihren Antrag unterschrieben zurückschicken.

Vielen Dank für Ihr Verständnis.`,
  },
};

export const PaymentMethodTexts = {
  title: 'Schließen Sie Ihren Vertrag jetzt online ab',
  label: 'Ihre Zahlungsweise',
  content: `Ich bin damit einverstanden, dass die bisherigen
  Vereinbarungen zur Zahlungsweise unverändert auch für den
  neuen, mir angezeigten Beitrag gelten.`,
  noError: '',
  errorMsg:
    'Bitte bestätigen Sie, dass Sie für diesen Abschluss keine Beratung benötigen',
};
