import { HandleGenderTypeProps, Salutations } from 'types';

export const handleGenderType = ({ salutation }: HandleGenderTypeProps) => {
  switch (salutation) {
    case Salutations.mr:
      return Salutations.herr;
    case Salutations.ms:
      return Salutations.frau;
    default:
      return Salutations.herr;
  }
};
