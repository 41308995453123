import { createAsyncThunk } from '@reduxjs/toolkit';
import { store } from 'store/store';
import { GetInitialOfferProps } from './api-calls.types';
import { RequestService } from '../routes';

export const fetchInitialOffer = createAsyncThunk(
  '',
  async ({ dispatch, angebotsnr, appProps }: GetInitialOfferProps) => {
    const response = await RequestService.offerData({
      angebotsnr,
      dispatch,
      appProps,
    });

    return response;
  },
);
export const getInititalOfferData = ({
  angebotsnr,
  dispatch,
  appProps,
}: GetInitialOfferProps) =>
  store.dispatch(fetchInitialOffer({ angebotsnr, dispatch, appProps }));
