import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { useAppSelector } from 'store/hooks';
import { StoreProps } from 'types/app.types';

export const LoaderDimmed = () => {
  const uiState = useAppSelector((state: StoreProps) => state.userInterface);
  const { loaderState } = uiState;

  return <LoadingSpinner show={loaderState.show} />;
};
