import { AnyAction, Dispatch } from 'redux';
import { setDimensions } from 'store/reducers/user-interface-reducer';

export const handleScreenResize = ({
  dispatch,
}: {
  dispatch: Dispatch<AnyAction>;
}) => {
  const handleResize = () => {
    dispatch(
      setDimensions({
        height: window.innerHeight,

        width: window.innerWidth,
      }),
    );
  };

  window.addEventListener('resize', handleResize);
};

export const headerContentWidth = () =>
  document.getElementById('payment-confirmation')?.offsetWidth;

export const getAemHeaderHeight = () => {
  const aemHight = document.querySelector('.page__navi')?.clientHeight;
  return aemHight !== undefined ? aemHight + 1 : 0;
};
