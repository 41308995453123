import { loaderStates } from 'store/actions/user-interface-actions';
import { ErrorMessages } from 'store/constants/components-static-texts';
import { setAppProps } from 'store/reducers/aem-tracking-reducer';
import {
  setErrorState,
  setLoaderState,
  setSuccessMessage,
  setIsAppInitialized,
} from 'store/reducers/user-interface-reducer';
import {
  ErrorPageName,
  MiscellaneousErrorTypes,
  ErrorMessages as ErrorMessagesLiteral,
} from 'types/app.types';
import { RequestService } from '../routes';
import { FinalOfferProps } from './api-calls.types';

export const finalOffer = ({
  businessId,
  bankNumber,
  dispatch,
}: FinalOfferProps) => {
  dispatch(setLoaderState(loaderStates.sendData));
  RequestService.finalOffer(businessId, bankNumber)
    .then(() => {
      dispatch(setSuccessMessage(true));
    })
    .catch((error) => {
      const { status } = error.message;
      dispatch(setIsAppInitialized(false));
      dispatch(
        setErrorState({
          state: true,
          status,
          title: ErrorMessages.engine.title,
          content: ErrorMessages.engine.content,
        }),
      );
      let statusLiteral = '';
      statusLiteral = 'serverError';
      dispatch(
        setAppProps({
          appName: '',
          appNameShort: '',
          pageName: ErrorPageName[statusLiteral],
          errorMsg: ErrorMessagesLiteral[statusLiteral],
          errorType: MiscellaneousErrorTypes[statusLiteral],
          transactionID: businessId,
        }),
      );
    })
    .finally(() => {
      dispatch(setLoaderState(loaderStates.clear));
    });
};
