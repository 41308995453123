import { ErrorMessages } from 'store/constants/components-static-texts';
import {
  setErrorState,
  setIsAppInitialized,
  setIsError,
} from 'store/reducers/user-interface-reducer';
import { RequestService } from '../routes';
import { DownloadFileProps } from './api-calls.types';

export const downloadFile = ({
  businessId,
  dispatch,
  setIsDownloading,
  setIsDownloaded,
}: DownloadFileProps) => {
  RequestService.downloadFile(businessId)
    .then(() => {
      setIsDownloading(false);
      setIsDownloaded(true);
    })
    .catch((error) => {
      dispatch(setIsAppInitialized(false));
      const { status } = error.message;

      dispatch(
        setErrorState({
          state: true,
          status,
          title: ErrorMessages.engine.title,
          content: ErrorMessages.engine.content,
        }),
      );
    });
  dispatch(setIsError(true));
};
