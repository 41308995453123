import React, { useState, useEffect } from 'react';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import Checkbox from '@eg/elements/Checkbox';
import { Link } from 'react-scroll';
import { ControlWithHint } from '@eg/elements/ControlWithHint';
import { finalOffer } from 'services/api/api-calls/final-offer-call';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { aemTracking as aemTrackingHelper } from 'helpers/aem-tracking';
import { createTrackingObject } from 'helpers/create-tracking-object';
import {
  getYearlyNormalPrice,
  getYearlyReducedPrice,
} from 'helpers/get-tracking-calculated-values';
import { downloadFile } from 'services/api/api-calls/download-file-call';
import { setIsAdviceWaiver } from 'store/reducers/person-data-reducer';
import {
  setIsFormSubmitted,
  setPaymentMethodConfirmationError,
} from 'store/reducers/user-interface-reducer';
import './Idd.scss';
import { heading3, heading4 } from 'helpers/handling-header-css-class';
import { getInsuranceStartDate } from 'helpers/date-format-converter';
import { IddButton } from 'components/IddButton/IddButton';
import { AppProps } from 'types/app.types';

const IddSubmitLeftSection = () => <span className="bold">2</span>;

const IddSubmitRightSection = () => (
  <>
    <span className="bold">Online abschließen</span>
    <br />
    <span className="idd__submit--small">zahlungspflichtiger Vertrag</span>
  </>
);

export const Idd: React.FC = () => {
  const dispatch = useAppDispatch();
  const { person, aemTracking } = useAppSelector((state) => state);
  const { appProps } = aemTracking;

  const { isPaymentMethodConfirmed } = useAppSelector(
    (state) => state.userInterface,
  );
  const { dimensions } = useAppSelector((state) => state.userInterface);

  const { isAdviceWaiver, isOriginalOffer } = person;
  const [onceChecked, setOneChecked] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { businessId } = person;

  useEffect(() => {
    if (isAdviceWaiver) {
      setOneChecked(true);
    }
    if (!isAdviceWaiver) {
      setIsDownloaded(false);
      setIsSubmitEnabled(false);
    }
  }, [isAdviceWaiver]);

  const appPropsWitoutPageName: AppProps = {
    appName: appProps.appName,
    appNameShort: appProps.appNameShort,
    errorMsg: appProps.errorMsg,
    errorType: appProps.errorType,
    transactionID: appProps.transactionID,
  };

  const trackingObject = createTrackingObject({
    event: 'GeneralClick',
    eventDetails: {
      clickedElement: isOriginalOffer
        ? 'anw:IDDdownload'
        : `${appProps.appNameShort}:IDDdownload`,
      clickType: 'other',
    },
    insuranceStart: getInsuranceStartDate(person.startInsuranceDate),
    productCombination: person.productCombination,
    calculatedValue: getYearlyNormalPrice(person.pricing.paymentMethodPrice),
    calculatedValueReduced: getYearlyReducedPrice(
      person.pricing.discountAmount,
      person.pricing.paymentMethodPrice,
    ),
    actionNumber: person.actionNumber,
    transactionID: person.businessId,
    appProps: appPropsWitoutPageName,
    transactionID_VSNR: person.transactionOfferNumber,
  });

  const trackClick = () => {
    aemTrackingHelper.push(trackingObject);
  };

  useEffect(() => {
    if (isDownloaded) {
      setIsSubmitEnabled(true);
    }
  }, [isDownloaded]);

  const handleDownload = () => {
    trackClick();
    setIsDownloading(true);
    downloadFile({ businessId, dispatch, setIsDownloading, setIsDownloaded });
  };

  const submit = () => {
    const bankNumber = person.policyPayer.iban;
    dispatch(setIsFormSubmitted(true));
    const addBankDataIfOriginalNumber = isOriginalOffer ? { bankNumber } : {};
    finalOffer({
      businessId,
      ...addBankDataIfOriginalNumber,
      dispatch,
    });
  };

  const handleSubmit = () => {
    if (isPaymentMethodConfirmed) {
      submit();
    } else {
      dispatch(setPaymentMethodConfirmationError(true));
    }
  };

  return (
    <>
      <p className={`header ${heading3(dimensions.width)}`}>
        Schließen Sie jetzt Ihren Vertrag online ab.
      </p>
      <div className="idd">
        <div className="idd__box-with-lead">
          <p className={heading4(dimensions.width)}>
            EU-Vorgabe: Beratungspflicht
          </p>
          <p>
            Seit Februar 2018 gilt auch für Vertragsabschlüsse per Internet:
          </p>
          <p>
            Wir müssen Sie vorher beraten und dies dokumentieren. Sie können
            auch schnell und dafür ohne Beratung abschließen. Dann brauchen wir
            vor dem Abschluss Ihren Beratungsverzicht.
          </p>
        </div>
        <div className="idd__box-with-checkbox --checked">
          <ControlWithHint
            error={
              onceChecked && !isAdviceWaiver
                ? 'Bitte bestätigen Sie, dass Sie für diesen Abschluss keine Beratung benötigen'
                : ''
            }
          >
            <Checkbox
              style={{ marginTop: 0 }}
              checked={isAdviceWaiver || false}
              data-testid="idd-checkbox"
              onChange={() => {
                dispatch(setIsAdviceWaiver(!isAdviceWaiver));
              }}
              label={
                <span className="checkbox-label">
                  Ich verzichte für diesen Vertragsabschluss auf eine
                  persönliche Beratung sowie deren Dokumentation. Dies kann sich
                  evtl. auf die Geltendmachung von Schadensersatzansprüchen
                  nachteilig auswirken.
                </span>
              }
            />
          </ControlWithHint>
        </div>
      </div>
      <div>
        <div className="idd__subContent">
          <p className={heading3(dimensions.width)}>Vertragsunterlagen</p>
          <p>
            Bitte speichern Sie vor dem Online-Abschluss die Vertragsunterlagen
            und Informationen als PDF und schließen Sie dann online ab.
          </p>
          <IddButton
            leftSection="1"
            rightSection="Herunterladen"
            className="idd__DownloadButton"
            disabled={!isAdviceWaiver}
            onClick={handleDownload}
            loading={isDownloading}
            variant={isDownloaded ? 'secondary' : 'primary'}
            iconRight={isDownloaded ? CheckIcon : undefined}
          />
        </div>
        <div className="idd__subContent">
          <p className={heading3(dimensions.width)}>
            Zahlungspflichtiger Vertrag
          </p>
          <p>
            Bei Vertragsabschluss fallen für Sie keine weiteren Nebenkosten an.
          </p>
          <Link to="payment-confirmation">
            <IddButton
              leftSection={IddSubmitLeftSection}
              rightSection={IddSubmitRightSection}
              disabled={!isSubmitEnabled}
              onClick={handleSubmit}
              variant="primary"
              className="idd__SubmitButton"
            />
          </Link>
        </div>
      </div>
    </>
  );
};
