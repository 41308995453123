import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import {
  setPaymentMethodConfirmation,
  setPaymentMethodConfirmationError,
} from 'store/reducers/user-interface-reducer';
import { PolicyPayer } from 'types/app.types';
import { RequestService } from '../routes';

export type IbanValidationProps = {
  iban: string;
  dispatch: Dispatch<AnyAction>;
  setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  setBic: React.Dispatch<React.SetStateAction<string>>;
  setBankName: React.Dispatch<React.SetStateAction<string>>;
  policyPayer: PolicyPayer;
};

export const ibanValidation = ({
  iban,
  setIsFetching,
  setIsValid,
  setBic,
  setBankName,
  dispatch,
}: IbanValidationProps) => {
  setIsFetching(true);
  RequestService.ibanValidation(iban)
    .then(({ data }) => {
      dispatch(setPaymentMethodConfirmationError(false));
      dispatch(setPaymentMethodConfirmation(true));
      setBic(data.bic);
      setBankName(data.financialInstitutionName);
      setIsFetching(false);
      setIsValid(true);
    })
    .catch(() => {
      setIsFetching(false);
      setIsValid(false);
      dispatch(setPaymentMethodConfirmation(false));
    });
};
