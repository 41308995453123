import { IbanSection } from 'components/PaymentComponent/IbanSection/IbanSection';
import { PaymentConfirmation } from 'components/PaymentComponent/PaymentConfirmation';

export const PaymentComponent = ({
  isOriginalOffer,
}: {
  isOriginalOffer: boolean | null;
}) => {
  return isOriginalOffer ? <IbanSection /> : <PaymentConfirmation />;
};
