/* eslint-disable no-nested-ternary */

import React from 'react';
import { useSelector } from 'react-redux';
import { StoreProps } from 'types/app.types';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import { heading5 } from 'helpers/handling-header-css-class';
import { useAppSelector } from 'store/hooks';
import { Pricing } from '../Pricing';
import styles from './TarifUndBeitrag.module.scss';

export const TarifUndBeitrag = () => {
  const { person } = useSelector((state: StoreProps) => state);
  const { dimensions } = useAppSelector((state) => state.userInterface);

  return (
    <div className={styles.tarifContainer}>
      <div className={styles.tarifSection}>
        <p className={heading5(dimensions.width)}>Gewählter Tarif</p>
        <p className={styles.tarifSection__values}>{person.productName}</p>
      </div>
      {Boolean(person.productDetails.length) && (
        <div className={styles.tarifSection}>
          <p className={heading5(dimensions.width)}>Leistungen</p>
          <div>
            {person.productDetails.map((detail: string) => (
              <div className={styles.leistungContainer} key={detail}>
                <CheckColorIcon />
                {detail.split(' ')[0].includes('%') ? (
                  <p className={styles.tarifSection__values}>
                    <strong>{`${detail.split('%')[0]}%`}</strong>
                    {detail.split('%')[1]}{' '}
                  </p>
                ) : detail.startsWith('1x (je Versicherungsjahr)') ? (
                  <p className={styles.tarifSection__values}>
                    <strong>{detail.slice(0, 25)}</strong> {detail.slice(25)}
                  </p>
                ) : detail.includes('Gesundheitsfragen') &&
                  detail.includes('Wartezeit') ? (
                  <p className={styles.tarifSection__values}>
                    {detail.slice(0, 8)}
                    <strong>{detail.slice(8, 31)}</strong>
                    {detail.slice(31, 35)}
                    <strong>{detail.slice(35)}</strong>
                  </p>
                ) : (
                  <p className={styles.tarifSection__values}>
                    <strong>{detail}</strong>
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {person.isOriginalOffer ? (
        <div className={styles.tarifSection}>
          <p className={heading5(dimensions.width)}>Beitrag</p>
          <Pricing person={person} />
        </div>
      ) : (
        <>
          <div className={styles.tarifSection}>
            <p className={heading5(dimensions.width)}>Ihr bisheriger Beitrag</p>
            <Pricing person={person} isTw />
          </div>
          <div className={styles.tarifSection}>
            <p className={heading5(dimensions.width)}>Ihr neuer Beitrag</p>
            <Pricing person={person} />
          </div>
        </>
      )}
    </div>
  );
};
