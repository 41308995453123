import { loaderLabels } from 'config/loader-labels';

export const loaderStates = {
  clear: {
    show: false,
    infoLabel: loaderLabels.clear,
  },
  networkError: {
    show: true,
    infoLabel: loaderLabels.networkErrorMessage,
  },
  sendData: {
    show: true,
    infoLabel: loaderLabels.sendingData,
  },
  fileIsDownloading: {
    show: true,
    infoLabel: loaderLabels.fileDownload,
  },
  // setErrorState: ({
  //   state,
  //   title,
  //   content,
  // }: {
  //   state: boolean;
  //   title: string;
  //   content: string;
  // }) => {
  //   return { state, title, content };
  // },
  // isModalOpen: ({ state, text }: { state: boolean; text: string }) => {
  //   return { open: state, text };
  // },
  // setSuccessMessage: (state: boolean) => {
  //   return { state };
  // },
};
