import styles from './Asterisk.module.scss';

export const Asterisk = () => {
  return (
    <p className={styles.asterisk}>
      <span>*</span>
      Es handelt sich um eine vereinfachte Darstellung. Altersbedingte
      Beitragserhöhungen können darin nicht berücksichtigt werden. Weitere
      Informationen zur Beitragsentwicklung finden Sie in den
      Vertragsunterlagen.
    </p>
  );
};
