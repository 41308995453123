import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import { heading2 } from 'helpers/handling-header-css-class';
import { handleGenderType } from 'helpers/gender-type-reconizer';
import { useAppSelector } from 'store/hooks';
import styles from './SuccessMessage.module.scss';

export const SuccessMessageHeader = () => {
  const { person } = useAppSelector((state) => state);
  const { dimensions } = useAppSelector((state) => state.userInterface);

  const user = `${handleGenderType({
    salutation: person.policyPayer.salutation,
  })} 
    ${person.policyPayer.firstName} ${person.policyPayer.lastName}!`;

  return (
    <div className={styles.header}>
      <CheckColorIcon />
      <p className={heading2(dimensions.width)}>
        Vielen Dank, <br />
        {user}
      </p>
      <span>Ihr Antrag ist angekommen. Sie müssen nichts weiter tun.</span>
    </div>
  );
};
