import classNames from 'classnames';

const isMobile = (width: number): boolean => width < 768;

export const heading2 = (width: number) =>
  classNames(isMobile(width) ? 'esc_h3' : 'esc_h2');
export const heading3 = (width: number) =>
  classNames(isMobile(width) ? 'esc_h4' : 'esc_h3');
export const heading4 = (width: number) =>
  classNames(isMobile(width) ? 'esc_h5' : 'esc_h4');
export const heading5 = (width: number) =>
  classNames(isMobile(width) ? 'esc_h6' : 'esc_h5');
