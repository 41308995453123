import {
  AemTrackingProps,
  PersonProps,
  UserInterfaceProps,
} from 'types/app.types';
import { loaderLabels } from 'config/loader-labels';

export const UserInterfaceInitialState: UserInterfaceProps = {
  isAppInitialized: false,
  loaderState: {
    show: true,
    infoLabel: loaderLabels.pageIsLoading,
  },
  isUserAuthorized: true,
  isButtonLocked: false,
  isMobileView: false,
  isError: false,
  showAsterisk: false,
  modalState: {
    isModalOpen: false,
    modalText: '',
  },
  isModalOpen: false,
  modalText: '',
  errorState: { state: false, status: 0, title: '', content: '' },
  success: false,
  isPaymentMethodConfirmed: false,
  isPaymentMethodConfirmationError: false,
  requestStatus: 0,
  isFormSubmitted: false,
  aemHeaderMarginTop: 0,
  dimensions: { width: window.innerWidth, height: window.innerHeight },
};

export const PersonInitialState: PersonProps = {
  businessId: '',
  isOriginalOffer: null,
  startInsuranceDate: '',
  insuranceName: '',
  productName: '',
  productCombination: '',
  actionNumber: '',
  productDetails: [],
  currentOffers: [],
  previousOffers: [],
  isPolicyHolderAlsoInsuredPerson: false,
  salesStrategy: '',
  policyHolder: {
    salutation: 'MR',
    firstName: '',
    lastName: '',
  },
  policyPayer: {
    salutation: 'MR',
    firstName: '',
    lastName: '',
    iban: '',
    bankName: '',
    bankCity: '',
    bic: '',
  },
  insuredPersons: [
    {
      salutation: 'MR',
      firstName: '',
      lastName: '',
    },
  ],
  pricing: {
    paymentMethodPrice: 0,
    paymentMethod: '',
    discountMonthsCount: 0,
    discountAmount: 0,
    oldOfferAmount: 0,
  },
  isAdviceWaiver: false,
  transactionOfferNumber: '',
};

export const AemTrackingInitialState: AemTrackingProps = {
  event: '',
  eventdetails: {
    clickedElement: '',
    clickType: '',
  },
  page: {
    attributes: {
      displayedBrand: '',
      viewPort: '',
    },
    pageInfo: {
      sysEnv: '',
      issueDate: '',
      pageName: '',
    },
    category: {
      pagePurpose: '',
      pageType: '',
      primaryCategory: '',
    },
  },
  product: [
    {
      attributes: {
        insuranceStart: '',
        productBranch: '',
        productCombination: '',
        productOwner: '',
      },
      category: {
        primaryCategory: '',
      },
      productInfo: {
        productName: '',
      },
    },
  ],
  transaction: {
    attributes: {
      conversionType: '',
      calculatedValue: 0,
      calculatedValueReduced: 0,
      offer: {
        actionNumber: '',
        transactionID: '',
      },
    },
  },
  miscellaneous: { errors: { errorFlag: false, errorType: '' } },
  appProps: {
    appName: '',
    appNameShort: '',
    pageName: '',
    errorMsg: '',
    errorType: '',
    transactionID: '',
  },
};
