import { heading5 } from 'helpers/handling-header-css-class';
import { stringifyTariffArray } from 'helpers/stringify-tariff-array';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { StoreProps } from 'types/app.types';
import styles from './GutZuWissen.module.scss';

export const GutZuWissen = () => {
  const { currentOffers, previousOffers } = useSelector(
    (state: StoreProps) => state.person,
  );

  const { dimensions } = useAppSelector((state) => state.userInterface);

  const [currentOffersString, previousOffersString] = [
    stringifyTariffArray(currentOffers),
    stringifyTariffArray(previousOffers),
  ];

  return (
    <div className={styles.container}>
      <p className={heading5(dimensions.width)}>
        Ihr alter Tarif endet mit Beginn des neuen Tarifs.
      </p>
      <p>
        Der Versicherungsschutz besteht, sobald die Vertragsänderung zustande
        gekommen ist, frühestens jedoch zu dem im Versicherungsschein
        angegebenen Beginn. Damit endet der Versicherungsschutz nach Tarif{' '}
        {previousOffersString}.
      </p>

      <p className={heading5(dimensions.width)}>
        Neue Rechnung für Zahnersatz, neue Maßnahme?
      </p>
      <p>
        Dann gilt der <b>neue</b> Tarif. Für bereits <b>vor</b> dem geänderten
        Versicherungsschutz fehlende und noch nicht dauerhaft ersetzte Zähne
        bzw. angeratene oder durchgeführte Zahnersatzmaßnahmen erfolgt die
        Leistungsregulierung nach den Bedingungen Ihres <b>bisherigen</b> Tarifs{' '}
        {previousOffersString}.
      </p>
      <p>
        <b>Laufzeit-Vorteil:</b> Die Versicherungszeit und bereits ausbezahlte
        Leistungen im bisherigen Tarif {previousOffersString} werden auf die
        Leistungsbegrenzungen des neuen Tarifs angerechnet. Innerhalb der ersten
        4 Versicherungsjahre gelten Leistungsbegrenzungen.
      </p>

      <p className={heading5(dimensions.width)}>
        Es gelten die Vertragsbeding-ungen des neuen Tarifs{' '}
        {currentOffersString}.
      </p>
      <p>
        <b>Außerdem gilt:</b> Sie sind damit einverstanden, dass der
        Versicherungsschutz bereits vor Ende der Widerrufsfrist beginnt. Weitere
        bestehende Zahnerhalttarife sind von dem Tarifwechsel nicht betroffen.
        Sie wechseln von Tarif {previousOffersString} in den Tarif{' '}
        {currentOffersString}.
      </p>
    </div>
  );
};
