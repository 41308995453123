import { translatePeriods } from 'helpers/translate-periods';
import { PricingProps } from './Pricing.types';
import styles from '../TarifUndBeitrag/TarifUndBeitrag.module.scss';

export const Pricing = ({ person, isTw = false }: PricingProps) => {
  return (
    <>
      {isTw ? (
        <div>
          <p className={styles.tarifSection__values}>
            {person.pricing.oldOfferAmount.toFixed(2).replace(/\./g, ',')}
            &nbsp;€&nbsp;monatlich
          </p>
        </div>
      ) : (
        <div>
          {person.pricing.discountAmount ? (
            <>
              <p className={styles.tarifSection__values}>
                {person.pricing.discountAmount.toFixed(2).replace(/\./g, ',')}
                &nbsp;€&nbsp;
                {translatePeriods(person.pricing.paymentMethod)}
                {` (Startbeitrag 1. bis ${person.pricing.discountMonthsCount}. Monat)*`}
              </p>
              <p className={styles.tarifSection__values}>
                {person.pricing?.paymentMethodPrice
                  .toFixed(2)
                  .replace(/\./g, ',')}
                &nbsp;€&nbsp;
                {translatePeriods(person.pricing.paymentMethod)}
                {` (Tarifbeitrag ab dem 
                  ${person.pricing.discountMonthsCount + 1}. Monat)*`}
              </p>
            </>
          ) : (
            <p className={styles.tarifSection__values}>
              {person.pricing.paymentMethodPrice.toFixed(2).replace(/\./g, ',')}
              &nbsp;€&nbsp;
              {translatePeriods(person.pricing.paymentMethod)}
            </p>
          )}
        </div>
      )}
    </>
  );
};
