/* eslint-disable react/no-array-index-key */
import { ChangeEvent, useEffect, useState } from 'react';
import FormRow from '@eg/elements/FormRow';
import InputRow from '@eg/elements/InputRow';
import LoaderIcon from '@eg/elements/Icons/LoaderIcon';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import ToolTipIcon from '@eg/elements/TooltipIcon';
import { StoreProps } from 'types/app.types';
import { capitalizeFirstLetter } from 'helpers/capitalize-first-letter';
import {
  setBankData,
  setIsAdviceWaiver,
} from 'store/reducers/person-data-reducer';
import {
  BottomInfo,
  IbanComponentLabels,
  IbanResponseMessages,
  IbanSuccessValidationTexts,
  ModalTexts,
  Placeholders,
} from 'store/constants/components-static-texts';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ibanValidation } from 'services/api/api-calls/iban-validation-call';
import { setPaymentMethodConfirmation } from 'store/reducers/user-interface-reducer';
import { heading3 } from 'helpers/handling-header-css-class';
import styles from './IbanSection.module.scss';
import './IbanSection.scss';

export const IbanSection = () => {
  const dispatch = useAppDispatch();
  const [iban, setIban] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isIbanChecked, setIsIbanChecked] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [bic, setBic] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const { policyHolder, policyPayer } = useAppSelector(
    (state: StoreProps) => state.person,
  );
  const { isPaymentMethodConfirmationError } = useAppSelector(
    (state: StoreProps) => state.userInterface,
  );
  const { dimensions } = useAppSelector((state) => state.userInterface);

  const ibanLength = iban.match(/^DE/) ? 22 : 15;

  const checkBankData = () => {
    setIsIbanChecked(true);
    if (iban.length > ibanLength) {
      ibanValidation({
        dispatch,
        setBankName,
        setBic,
        setIsFetching,
        setIsValid,
        iban,
        policyPayer,
      });
    }
  };

  const handleIban = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsAdviceWaiver(false));
    const { target } = e;
    let position = target.selectionEnd || 0;
    const { length } = target.value;
    target.value = target.value
      .toUpperCase()
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    position +=
      target.value.charAt(position - 1) === ' ' &&
      target.value.charAt(length - 1) === ' ' &&
      length !== target.value.length
        ? 1
        : 0;
    target.selectionEnd = position;
    setIban(target.value);
  };

  useEffect(() => {
    if (iban.length < 22) {
      dispatch(setPaymentMethodConfirmation(false));
      setIsValid(false);
    }
  }, [iban, dispatch]);

  useEffect(() => {
    dispatch(setBankData(iban));
  }, [dispatch, iban]);

  useEffect(() => {
    dispatch(setBankData(iban));
    dispatch(setIsAdviceWaiver(false));
  }, [dispatch, iban]);

  const maxIbanLength = 27;

  const classHeader = `${styles.header} ${heading3(dimensions.width)}`;
  return (
    <div id="payment-confirmation" className={styles.view}>
      <div id="payment_header">
        <p className={classHeader}>{IbanComponentLabels.header}</p>
        <div className={styles.subheader}>
          <p>{IbanComponentLabels.subheader}</p>
          <ToolTipIcon isModal>{ModalTexts.abschluss}</ToolTipIcon>
        </div>
      </div>
      <FormRow
        className={styles.kontoinhaber}
        label={IbanComponentLabels.salutation}
      >
        <p>
          {policyHolder.firstName} {policyHolder.lastName}
        </p>
      </FormRow>
      <InputRow
        maxLength={maxIbanLength}
        className={styles.input}
        label="IBAN"
        onChange={handleIban}
        onBlur={checkBankData}
        error={
          ((isIbanChecked && !isFetching && !isValid) ||
            isPaymentMethodConfirmationError) &&
          IbanResponseMessages.nonSepaArea
        }
        placeholder={Placeholders.iban}
        adornmentRight={isFetching ? <LoaderIcon /> : undefined}
      />
      <div
        className={styles.validation}
        style={{ display: isValid ? 'flex' : 'none' }}
      >
        <CheckColorIcon className={styles.icon} height={24} width={24} />
        <span className={styles.p}>
          <p>{IbanSuccessValidationTexts.label}</p>
          <p>
            {Object.keys(IbanSuccessValidationTexts)[1].toUpperCase()}: {bic}
            {IbanSuccessValidationTexts.bic} {bankName}
          </p>
        </span>
      </div>
      <div className={styles.bottom_info}>
        {Object.values(BottomInfo).map((item, index) => {
          if (index < 2) {
            return <p key={index}>{item}</p>;
          }
          if (index === 2) {
            return (
              <p key={index}>
                <span className={styles.bottom_info_bold}>
                  {capitalizeFirstLetter(Object.keys(BottomInfo)[2])}
                </span>
                {item}
              </p>
            );
          }
          if (index === 3) {
            return (
              <div key={index} className={styles.bottom_info_icon}>
                <p>{item}</p>
                <ToolTipIcon isModal>{ModalTexts.hinweise}</ToolTipIcon>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
