import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AemTrackingInitialState } from 'store/constants/initial-state';
import {
  AemTrackingProps,
  AppProps,
  EventDetails,
  Page,
  Product,
  TrackingEvent,
} from 'types/app.types';

const initialState = AemTrackingInitialState;

const aemTracking = createSlice({
  name: 'aemTracking',
  initialState,
  reducers: {
    setTrackingObject: (state, action: PayloadAction<AemTrackingProps>) => {
      return action.payload;
    },

    setEvent: (state, action: PayloadAction<TrackingEvent>) => {
      return { ...state, event: action.payload };
    },

    setEventDetails: (state, action: PayloadAction<EventDetails>) => {
      return {
        ...state,
        eventdetails: action.payload,
      };
    },

    setPage: (state, action: PayloadAction<Page>) => {
      return { ...state, page: action.payload };
    },

    setPageName: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        page: {
          ...state.page,
          pageInfo: { ...state.page.pageInfo, pageName: action.payload },
        },
      };
    },

    setProduct: (state, action: PayloadAction<Product>) => {
      return { ...state, product: [...state.product, action.payload] };
    },

    setTransactionConversionType: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          conversionType: action.payload,
        },
      };
    },

    setMiscellaneousErrorsFlag: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          errors: { ...state.miscellaneous.errors, errorFlag: action.payload },
        },
      };
    },

    setMiscellaneousErrorsType: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          errors: { ...state.miscellaneous.errors, errorType: action.payload },
        },
      };
    },
    setAppProps: (state, action: PayloadAction<AppProps>) => {
      const {
        appName,
        appNameShort,
        pageName,
        errorMsg,
        errorType,
        transactionID,
      } = action.payload;
      return {
        ...state,
        appProps: {
          appName,
          appNameShort,
          pageName,
          errorMsg,
          errorType,
          transactionID,
        },
      };
    },
  },
});

export const {
  setEvent,
  setEventDetails,
  setPage,
  setPageName,
  setProduct,
  setTransactionConversionType,
  setMiscellaneousErrorsFlag,
  setMiscellaneousErrorsType,
  setAppProps,
} = aemTracking.actions;

export const aemTrackingReducer = aemTracking;
