import styles from './Gdpr.module.scss';

export const Gdpr = () => {
  return (
    <div className={styles.container}>
      <p>
        Die von Ihnen angegebenen <b>personenbezogenen Daten</b> bzw.
        persönliche Informationen zur Identität (z. B. Adresse, Geburtsdatum,
        Telefonnummer) werden <b>zu Ihrer eigenen Sicherheit nicht angezeigt</b>
        . Ihre Angaben sind bei ERGO gespeichert und müssen von Ihnen nicht
        ergänzt werden.
      </p>
      <p>
        Bei der Verarbeitung personenbezogener Daten beachtet ERGO die
        Vorschriften der EU-Datenschutz-Grundverordnung. Ausführliche
        Informationen finden Sie im Datenschutz-Bereich.
      </p>
    </div>
  );
};
