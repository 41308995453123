import { useAppSelector } from 'store/hooks';
import { heading3, heading4 } from 'helpers/handling-header-css-class';
import { getInsuranceStartDate } from 'helpers/date-format-converter';
import { Pricing } from '../Pricing';
import styles from './SuccessMessage.module.scss';

export const SuccessMessage = () => {
  const { person } = useAppSelector((state) => state);
  const { dimensions } = useAppSelector((state) => state.userInterface);

  return (
    <div className={styles.successMessage}>
      <div className={styles.content}>
        <p>
          Herzlichen Glückwunsch! Jetzt haben Sie gut lachen, denn Sie sind
          perfekt abgesichert.
        </p>
        <p>Ihre Vertragsunterlagen bekommen Sie in wenigen Tagen per Post.</p>
      </div>
      <h3 className={heading3(dimensions.width)}>
        Ihre {person.insuranceName}
      </h3>
      <div className={styles.card}>
        <div className={styles.card__item}>
          <p className={heading4(dimensions.width)}>Gewählter Tarif</p>
          <p>{person.productName}</p>
        </div>
        <div className={styles.card__item}>
          <p className={heading4(dimensions.width)}>Versicherungsbeginn</p>
          <p>
            {person.startInsuranceDate &&
              getInsuranceStartDate(person.startInsuranceDate)}
          </p>
        </div>
        {person.isOriginalOffer ? (
          <div className={styles.card__item}>
            <p className={heading4(dimensions.width)}>Beitrag</p>
            <Pricing person={person} />
          </div>
        ) : (
          <>
            <div className={styles.card__item}>
              <p className={heading4(dimensions.width)}>
                Ihr bisheriger Beitrag
              </p>
              <Pricing person={person} isTw />
            </div>
            <div className={styles.card__item}>
              <p className={heading4(dimensions.width)}>Ihr neuer Beitrag</p>
              <Pricing person={person} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
