import { fileName } from 'config';
import fileDownload from 'js-file-download';
import { loaderStates } from 'store/actions/user-interface-actions';
import { ErrorMessages as errorMessagesStatic } from 'store/constants/components-static-texts';
import { setAppProps } from 'store/reducers/aem-tracking-reducer';
import {
  setErrorState,
  setIsAppInitialized,
  setLoaderState,
  setRequestStatus,
  setSuccessMessage,
} from 'store/reducers/user-interface-reducer';
import {
  AppNamesGerman,
  AppNamesShort,
  AppProps,
  ErrorMessages,
  ErrorPageName,
  HttpStatus,
  MiscellaneousErrorTypes,
} from 'types/app.types';

import api from './api';
import { GetInitialOfferProps } from './api-calls/api-calls.types';
import { apiUrl } from './endpoints';

export const RequestService = {
  offerData: async ({
    dispatch,
    angebotsnr,
    appProps,
  }: GetInitialOfferProps) => {
    const response = await api
      .get(`${apiUrl.initialOfferData}${angebotsnr}`)
      .then((r) => {
        dispatch(setRequestStatus(HttpStatus.success));

        const {
          currentOffers,
          previousOffers,
          isOriginalOffer,
        }: {
          currentOffers: string[];
          previousOffers: string[];
          isOriginalOffer: boolean;
        } = r.data;

        let isUpselling: boolean;

        const appNameShort = (): AppProps['appNameShort'] => {
          const filteredProduct = currentOffers.filter((product) => {
            return previousOffers.some(
              (previousProduct) => previousProduct === product,
            );
          });
          isUpselling = filteredProduct[0] !== undefined;

          if (isOriginalOffer) {
            return AppNamesShort.angebotswandlung;
          }

          return isUpselling
            ? AppNamesShort.upselling
            : AppNamesShort.tarifwechsel;
        };

        const appName = () => {
          if (isOriginalOffer) {
            return AppNamesGerman.OfferSale;
          }

          return isUpselling
            ? AppNamesGerman.Upselling
            : AppNamesGerman.ChangeTariff;
        };

        const pageName = `${appNameShort()}:${
          isOriginalOffer ? 'Angebot Wiederaufruf' : 'Basisdaten'
        }`;

        dispatch(
          setAppProps({
            appName: appName(),
            pageName,
            appNameShort: appNameShort(),
            errorMsg: '',
            errorType: '',
            transactionID: '',
          }),
        );

        dispatch(setSuccessMessage(false));
        dispatch(setIsAppInitialized(true));
        dispatch(setLoaderState(loaderStates.clear));

        return r.data;
      })
      .catch((error) => {
        const { status, data } = error.response;
        let appName;
        let pageName;
        let statusLiteral = '';
        const { appNameShortcut, businessId } = data.message;
        if (status === HttpStatus.serverError) {
          statusLiteral = 'serverError';
          dispatch(
            setErrorState({
              state: true,
              status,
              title: errorMessagesStatic.engine.title,
              content: errorMessagesStatic.engine.content,
            }),
          );
          dispatch(
            setAppProps({
              ...appProps,
              appName: appNameShortcut,
            }),
          );
        } else {
          dispatch(
            setErrorState({
              state: true,
              status,
              title: errorMessagesStatic.badRequest.title,
              content: errorMessagesStatic.badRequest.content,
            }),
          );
          if (status === HttpStatus.unauthorized) {
            statusLiteral = 'unauthorized';
          }
          if (status === HttpStatus.badRequest) {
            statusLiteral = 'badRequest';
          }
          if (status === HttpStatus.notFound) {
            statusLiteral = 'notFound';
          }
          if (status === HttpStatus.offerExpired) {
            statusLiteral = 'offerExpired';
          }
          if (status === HttpStatus.undefined) {
            statusLiteral = 'undefined';
          }
        }
        if (appNameShortcut === 'tw/ups') {
          appName = AppNamesGerman.ChangeTariff;
          pageName = `${appNameShortcut}${ErrorPageName[statusLiteral]}`;
        } else {
          appName = AppNamesGerman.OfferSale;
          pageName = `${AppNamesShort.angebotswandlung}:Angebot Wiederaufruf Fehler`;
        }
        dispatch(
          setAppProps({
            appName,
            appNameShort: appNameShortcut,
            pageName,
            errorMsg: ErrorMessages[statusLiteral],
            errorType: MiscellaneousErrorTypes[statusLiteral],
            transactionID: businessId,
          }),
        );
        dispatch(setLoaderState(loaderStates.clear));
      });
    return response;
  },
  finalOffer: async (businessId: string, bankNumber: string | undefined) => {
    return api.post(`${apiUrl.finalOffer}/${businessId}`, {
      bankNumber,
    });
  },
  downloadFile: async (businessId: string) => {
    await api
      .get(`${apiUrl.downloadFile}/${businessId}`, {
        responseType: 'blob',
      })
      .then((r) => {
        fileDownload(new Blob([r.data]), fileName);
      });
  },
  ibanValidation: (iban: string) => {
    return api.post(apiUrl.ibanValidation, { bankNumber: iban });
  },
};
