import { MessageBox } from '@eg/elements/MessageBox';
import { useAppSelector } from 'store/hooks';
import { ErrorMessageProps } from './ErrorMessage.types';
import styles from './ErrorMessage.module.scss';

export const ErrorMessage: React.FC<ErrorMessageProps> = () => {
  const { errorState } = useAppSelector((state) => state.userInterface);

  return (
    <MessageBox type="error" className={styles.messageBox}>
      <h2>{errorState.title}</h2>
      <p>{errorState.content}</p>
    </MessageBox>
  );
};
