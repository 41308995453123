import { Modal as PopUp } from '@eg/elements/Modal';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { setModalState } from 'store/reducers/user-interface-reducer';
import { StoreProps } from 'types/app.types';
import styles from './Modal.module.scss';

export const Modal = () => {
  const dispatch = useAppDispatch();
  const { modalState } = useSelector(
    (state: StoreProps) => state.userInterface,
  );

  const closeDialog = () => {
    dispatch(
      setModalState({
        isModalOpen: false,
        modalText: '',
      }),
    );
  };

  return (
    <PopUp
      dismissible
      useEscKeyToDismiss
      onDismiss={closeDialog}
      open={modalState.isModalOpen}
    >
      <p className={styles.text}>{modalState.modalText}</p>
    </PopUp>
  );
};
