export enum Endpoints {
  initialOfferData,
  fileDownload,
  finalOffer,
  ibanValidation,
}

export enum RequestFormErrorMessages {
  conceptName = 'conceptName',
}

export interface FormSelectOptions {
  name: string;
  value: string;
}

export interface Person {
  salutation: PersonTitle;
  firstName: string;
  lastName: string;
}

export type Salutation = 'Herr' | 'Frau';
export type PersonTitle = 'MR' | 'MS' | '';
export type HandleGenderTypeProps = {
  salutation: PersonTitle;
};
export enum Salutations {
  herr = 'Herr',
  frau = 'Frau',
  mr = 'MR',
  ms = 'MS',
}
