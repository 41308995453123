import { useAppSelector } from 'store/hooks';
import { Accordion, AccordionTitles } from 'types/app.types';
import { GutZuWissen } from 'components/GutZuWissen';
import { TarifUndBeitrag } from 'components/TarifUndBeitrag';
import { Details } from '@eg/elements/Details';
import { useEffect, useState, useMemo } from 'react';
import { heading3 } from 'helpers/handling-header-css-class';
import styles from './Angaben.module.scss';
import { CreateVPNode } from './CreateVPNode';
import { StartInsuranceDate } from './StartInsuranceDate';

export const AngabenDetails = () => {
  const { dimensions } = useAppSelector((state) => state.userInterface);
  const { person } = useAppSelector((state) => state);
  const { isOriginalOffer } = person;
  const [data, setData] = useState<Accordion[]>([]);

  const dataWithoutGutZuWissen = useMemo(
    () => [
      {
        title: AccordionTitles.tariffAndContribution,
        content: <TarifUndBeitrag />,
      },
      {
        title: AccordionTitles.startInsuranceDate,
        content: <StartInsuranceDate />,
      },
      {
        title: AccordionTitles.personalData,
        content: <CreateVPNode data={person} width={dimensions.width} />,
      },
    ],
    [person, dimensions.width],
  );
  const dataWithGutZuWissen = useMemo(
    () => [
      ...dataWithoutGutZuWissen,
      {
        title: AccordionTitles.goodToKnow,
        content: <GutZuWissen />,
      },
    ],
    [dataWithoutGutZuWissen],
  );

  useEffect(() => {
    if (isOriginalOffer) {
      setData(dataWithoutGutZuWissen);
    } else {
      setData(dataWithGutZuWissen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOriginalOffer]);
  const headerName = `${styles.angaben__heading} ${heading3(dimensions.width)}`;
  return (
    <>
      <p className={headerName}>Prüfen Sie Ihre Angaben</p>
      {data.map((row: Accordion) => (
        <Details key={row.title} summary={row.title}>
          {row.content}
        </Details>
      ))}
    </>
  );
};
