import React, { useEffect, useState } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import { ControlWithHint } from '@eg/elements/ControlWithHint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setPaymentMethodConfirmation,
  setPaymentMethodConfirmationError,
} from 'store/reducers/user-interface-reducer';
import { PaymentMethodTexts } from 'store/constants/components-static-texts';
import { heading3 } from 'helpers/handling-header-css-class';
import style from './PaymentConfirmation.module.scss';

export const PaymentConfirmation: React.FC = () => {
  const { isPaymentMethodConfirmed, isPaymentMethodConfirmationError } =
    useAppSelector((state) => state.userInterface);
  const { dimensions } = useAppSelector((state) => state.userInterface);

  const dispatch = useAppDispatch();
  const [itWasChecked, setItWasChecked] = useState<number>(0);
  const [isErrorMsg, setIsErrorMsg] = useState<boolean>(
    isPaymentMethodConfirmationError,
  );

  const handleCheckbox = () => {
    dispatch(setPaymentMethodConfirmation(!isPaymentMethodConfirmed));
    setItWasChecked(itWasChecked + 1);
  };

  useEffect(() => {
    if (
      isPaymentMethodConfirmationError ||
      (itWasChecked > 0 && !isPaymentMethodConfirmed)
    ) {
      setIsErrorMsg(true);
    } else {
      setIsErrorMsg(false);
    }
  }, [
    itWasChecked,
    isPaymentMethodConfirmed,
    isPaymentMethodConfirmationError,
  ]);

  useEffect(() => {
    if (isPaymentMethodConfirmed) {
      dispatch(setPaymentMethodConfirmationError(false));
    }
  }, [dispatch, isPaymentMethodConfirmed]);

  return (
    <div id="payment-confirmation">
      <p className={style.header}>{PaymentMethodTexts.title}</p>
      <div className={style.payment_confirmation}>
        <div className={style.payment_confirmation_box_with_lead}>
          <p className={heading3(dimensions.width)}>
            {PaymentMethodTexts.label}
          </p>
        </div>
        <div className={style.payment_confirmation_box_with_checkbox}>
          <ControlWithHint
            error={
              isErrorMsg
                ? PaymentMethodTexts.errorMsg
                : PaymentMethodTexts.noError
            }
          >
            <Checkbox
              style={{ marginTop: 0 }}
              checked={isPaymentMethodConfirmed}
              data-testid="payment_confirmation-checkbox"
              onChange={handleCheckbox}
              label={
                <span className={style.checkbox_label}>
                  {PaymentMethodTexts.content}
                </span>
              }
            />
          </ControlWithHint>
        </div>
      </div>
    </div>
  );
};
